.customBodyBg {
  background: #f7f8fa !important;
}
input.inputBg,
.ant-picker.inputBg{
  background: #FFF !important;
  font-size: 14px !important;
  line-height: 21px !important;
}
@media only screen and (max-width: 768.99px) {
  .inputAipa textarea{
    padding: 15px 20px !important;
  }
}
.ant-input-affix-wrapper.inputBg{
  background: #FFF !important;
  border: 1px solid rgba(18, 18, 73, 0.15) !important;
  background: #FFF !important;
  border-radius: 10px !important;
}
.addCaseLabel .ant-form-item-label >label{
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #11263C !important;
  /* color: #F00 !important; */
}
.addCaseHr {
  border-top: 1px dashed #11263c;
  opacity: 0.2;
  margin-top: -3px;
}
/************* AIPA Style ************/
.aipaBody{
  width:100%;
  position: relative;
  background: #FFF url('../public/images/body-bg1.png') no-repeat center center;
	background-size: cover !important;
  min-height:100vh;
}
@media only screen and (min-width: 992.99px) {
  .aipaBody{
    background: #FFF url('../public/images/aipa-desktop-bg.png') no-repeat center center;
  }
}
.paddingPage {
  padding-bottom: 15px !important;
}
textarea.textAreaStyle {
  width:100%;
  position: relative;
  height:100%;
  box-sizing: border-box!important;
  padding: 20px 25px!important;
	font-weight: 400!important;
	font-size: 16px;
	line-height: 30px;
	letter-spacing: -0.02em!important;
  background: rgba(255, 255, 255, 0.5) !important;
	box-shadow: 0px 0px 15px rgba(0, 114, 153, 0.05)!important;
	border-radius: 10px!important;
  min-height: 327px !important;
  max-height: 327px;
}
.ant-modal-title {
  font-family: 'Poppins', sans-serif !important;
}